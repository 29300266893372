<template>
  <div class="login-container">
    <div class="left-image">
      <img class="left-1" src="@/assets/login-2.png" alt="登录背景图">
      <img class="left-2" src="@/assets/login-1.png" alt="登录背景图">
    </div>
    <div class="right-login">
      <div class="login-card">
        <div class="login-header">
          <div>欢迎登录</div>
        </div>
        <div class="form-con">
          <login-form @on-success-valid="handleSubmit"></login-form>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import LoginForm from './components/login-form';
import { login, getUserInfo } from '@/api/login';
import { useRouter } from 'vue-router';
import { Message } from "view-ui-plus";

const modules_enum = {
  1: 'productBackend',
  2: 'orderBackend'
}
export default {
  components: {
    LoginForm
  },
  setup() {
    const router = useRouter();
    function handleSubmit ({ username, password }) {
      console.log(username)
      console.log(password)
      // initData()
      // history.pushState(null, "productBackend", "/productBackend")
      // return
      login({ username, password }).then(res => {
        console.log(res)
        if (res.data.code === 200) {
          const { token } = res.data;
          localStorage.setItem('TOKEN', token)
          getInfo(token)
        } else {
          Message.warning(res.data.msg)
        }
      })
    }

    function getInfo(token) {
      getUserInfo(token).then(res => {
        console.log(res)
        if (res.data.code === 200) {
          const userInfoData = res.data;
          // 模块权限
          localStorage.setItem('MODULES_PERMISSIONS', userInfoData.modules);
          // 订单模块
          localStorage.setItem('loginForm', userInfoData.user.userName);
          // 会员模块
          localStorage.setItem('userName', userInfoData.user.userName);
          // 商品模块
          localStorage.setItem('USERINFOSTORAGE', JSON.stringify(userInfoData))
          localStorage.setItem('CHANNELNAME', userInfoData.user.channelName)
          localStorage.setItem('CHANNELID', userInfoData.user.channelId)
          localStorage.setItem('BUSINESSCODE', userInfoData.user.businessCode)

          // 默认进入一个模块（根据用户权限判断）
          const defaultMoules = userInfoData.modules[0];
          const moduleName = modules_enum[defaultMoules];
          const routerName = `/${moduleName}`;

          console.log(defaultMoules)
          console.log(moduleName)
          console.log(routerName)

          // 登录成功后进入首页
          router.push('/home')
          history.pushState(null, moduleName, routerName)
        } else {
          Message.warning(res.data.msg)
        }
      })
    }

    return {
      handleSubmit
    }
  }
}
</script>

<style scoped lang="less">
.login-container {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.left-image {
  flex: 1;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/login-bg.png');
  background-size: cover;
  background-position: center;
  position: relative;
  min-width: 700px;
  .left-1 {
    display: block;
    position: relative;
    width: 200px;
    height: 43px;
    margin: 40px;
  }
  .left-2 {
    display: block;
    position: relative;
    // width: 680px;
    // height: 140px;
    margin: 0 auto;
    max-width: 90%;
    margin-top: 80px;
  }
}
.left-bg {
  font-size: 0;
  object-fit: cover; /* 让图片保持宽高比自适应填充容器，如果想拉伸图片可改为 object-fit: fill */
  max-width: 100%; /* 确保图片宽度不会超出父容器 */
  height: 100%; /* 确保图片高度不会超出父容器 */
}
.right-login {
  position: relative;
  width: 670px;
  min-width: 670px;
  background-color: #fff;
  z-index: 99;
  display: flex;
  justify-content: center; /* 让登录框在右侧登录区域内部垂直居中 */
  align-items: center; /* 让登录框在右侧登录区域内部水平居中 */
}
.login-card {
  width: 390px;
}
.login-header {
  font-weight: 500;
  font-size: 28px;
  color: #20242C;
  text-align: left;
  font-style: normal;
  margin-bottom: 60px;
}
</style>