<template>
  <Form ref="loginForm" :model="form" :rules="rules" @keydown.enter.native="handleSubmit">
    <FormItem prop="username">
      <Input v-model="form.username" size="large" placeholder="账号">
        <template #prefix>
          <Icon type="md-person" />
        </template>
      </Input>
    </FormItem>
    <FormItem prop="password" style="margin-top: 30px;">
      <Input type="password" v-model="form.password" size="large" placeholder="密码">
        <template #prefix>
          <Icon type="md-unlock" />
        </template>
      </Input>
    </FormItem>
    <FormItem style="margin-top: 70px;">
      <Button @click="handleSubmit" type="primary" size="large" long>登录</Button>
    </FormItem>
  </Form>
</template>
<script>
export default {
  name: 'LoginForm',
  props: {
    userNameRules: {
      type: Array,
      default: () => {
        return [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ]
      }
    },
    passwordRules: {
      type: Array,
      default: () => {
        return [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      }
    }
  },
  data () {
    return {
      form: {
        username: '',
        password: ''
      }
    }
  },
  computed: {
    rules () {
      return {
        username: this.userNameRules,
        password: this.passwordRules
      }
    }
  },
  methods: {
    handleSubmit () {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.$emit('on-success-valid', {
            username: this.form.username,
            password: this.form.password
          })
        }
      })
    }
  }
}
</script>
